import { createTheme } from '@mui/material/styles';

import typography from './typography';

const theme = createTheme({
  palette: {
    primary: {
      main: '#283A46',
      light: '#283A46',
      dark: '#283A46',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#D9D9D9',
      light: '#ECEBEC',
      dark: '#848B91',
      contrastText: '#ffffff',
    },
    background: {
      default: '#283A46',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    error: {
      main: '#FF5050',
      light: '#FFEDED',
      contrastText: '#142530',
      dark: '#D40300',
    },
    warning: {
      main: '#FFE32A',
      contrastText: '#142530',
      light: '#FEFFE8',
      dark: '#D4B800',
    },
    success: {
      main: '#00AF27',
      contrastText: '#142530',
      light: '#D3FFDD',
      dark: '#00801C',
    },
    info: {
      main: '#29B1FF',
      light: '#D4EFFE',
      contrastText: '#142530',
      dark: '#0070AF',
    },
    divider: 'rgba(0,0,0,0.16)',
  },
  typography,
  spacing: 8,
  direction: 'rtl',
  shape: {
    borderRadius: 0,
  },
});

export default theme;
