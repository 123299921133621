import { Box, Typography, styled } from '@mui/material';

import theme from './components/shared/theme';

interface IErrorPage {
  code: number;
  message: string;
  description?: string;
}

function ErrorPage({ code, message, description }: IErrorPage) {
  return (
    <MyContainer>
      <Typography sx={{ fontSize: 40 }}>{`${code} | ${message}`}</Typography>
      {description && <Typography variant="h3">{description}</Typography>}
    </MyContainer>
  );
}

export default ErrorPage;
const MyContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: theme.palette.background.default,
  color: 'rgba(255, 255, 255, 0.60)',
}));
