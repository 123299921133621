import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import FetchNodeDetails from './FetchNodeDetails';
import { MyTableCell, MyTableRow } from './shared/StyledComponents';

import { INodeNamePort } from '../services/etherService';

interface INodeGridProps {
  nodesInfo: INodeNamePort[];
  networkConfigAddress: string;
}

function NodeGrid({ nodesInfo, networkConfigAddress }: INodeGridProps) {
  return (
    <TableContainer sx={{ width: '100%' }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <MyTableRow>
            <MyTableCell>
              <Typography variant="h4">RBN node</Typography>
            </MyTableCell>
            <MyTableCell sx={{ width: { xs: '30%', sm: '15%' } }}>
              <Typography variant="h4">Block number</Typography>
            </MyTableCell>
          </MyTableRow>
        </TableHead>
        <TableBody>
          {nodesInfo.map((nodeInfo) => (
            <FetchNodeDetails
              key={`${nodeInfo.name}`}
              networkConfigAddress={networkConfigAddress}
              nodeInfo={nodeInfo}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NodeGrid;
