import { Box, styled, Typography } from '@mui/material';

import theme from './shared/theme';

import CheckIcon from '../images/check-icon.svg';
import ErrorIcon from '../images/error-icon.svg';
import WaitIcon from '../images/wait-icon.svg';
import WarningIcon from '../images/warning-icon.svg';

interface INetworkHealthProps {
  loading: boolean;
  networkSize: number;
  networkTolerance: number;
  activeNodes: number;
  inconsistentBlockNumbers: boolean;
}

enum StatusType {
  Error,
  Warning,
  Success,
}
interface IStatus {
  status: string;
  statusType: StatusType;
  loading: boolean;
}

function NetworkHealth({
  loading,
  networkSize,
  networkTolerance,
  activeNodes,
  inconsistentBlockNumbers,
}: INetworkHealthProps) {
  let status: string;
  let statusType: StatusType;

  if (loading) {
    status = 'Please wait, retrieving nodes from the network...';
    statusType = 2;
  } else if (networkSize === 0) {
    status = 'No nodes found';
    statusType = 0;
  } else if (activeNodes < networkSize - networkTolerance) {
    status = 'Active node count less than network tolerance';
    statusType = 0;
  } else if (
    activeNodes >= networkSize - networkTolerance &&
    activeNodes !== networkSize
  ) {
    status =
      'A majority of nodes within network tolerance are online with matching block number.';
    statusType = 2;
  } else if (networkSize === activeNodes) {
    if (inconsistentBlockNumbers) {
      status = 'Block numbers differ between nodes';
      statusType = 1;
    } else {
      status = 'All nodes online with matching block number';
      statusType = 2;
    }
  } else {
    status = 'Unexpected network setup';
    statusType = 0;
  }

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: theme.palette.primary.contrastText }}
      >
        Status
      </Typography>
      <Box>
        <SetStatus loading={loading} status={status} statusType={statusType} />
      </Box>
    </>
  );
}

function SetStatus({ status, statusType, loading }: IStatus) {
  if (statusType === 1) {
    return (
      <StatusComponent sx={{ backgroundColor: theme.palette.warning.light }}>
        <img src={WarningIcon} alt="warning icon" />
        <Typography variant="h6" sx={{ ml: '8px' }}>
          {status}
        </Typography>
      </StatusComponent>
    );
  }
  if (statusType === 2) {
    return (
      <StatusComponent sx={{ backgroundColor: theme.palette.success.light }}>
        {loading ? (
          <img src={WaitIcon} alt="wait icon" />
        ) : (
          <img src={CheckIcon} alt="check icon" />
        )}
        <Typography variant="h6" sx={{ ml: '8px' }}>
          {status}
        </Typography>
      </StatusComponent>
    );
  }
  return (
    <StatusComponent sx={{ backgroundColor: theme.palette.error.light }}>
      <img src={ErrorIcon} alt="error icon" />
      <Typography variant="h6" sx={{ ml: '8px' }}>
        {status}
      </Typography>
    </StatusComponent>
  );
}

const StatusComponent = styled(Box)(() => ({
  padding: '10px',
  borderRadius: '10px',
  width: '100%',
  marginTop: '8px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.info.contrastText,
}));

export default NetworkHealth;
