import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App';
import ErrorPage from './Error';

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="*"
          element={<ErrorPage code={404} message="Not Found" />}
        />
      </Routes>
    </Router>
  );
}
