import { ethers } from 'ethers';

/**
 * Converts an Ethereum BigNumber to a JavaScript integer.
 *
 * @param {ethers.BigNumber} bigNumber - The BigNumber to convert.
 * @returns {number} The converted JavaScript integer.
 */
export const convertBigNumberToInt = (bigNumber: ethers.BigNumber): number =>
  parseInt(bigNumber.toString(), 10);

/**
 * Creates a URL based on the provided host and taking TLS mode into account.
 *
 * @param {string} host - The host name or IP address.
 * @returns {string} The constructed URL.
 */
export const getUrl = (host: string): string =>
  `${process.env.REACT_APP_TLS_MODE}` === 'true' ? `https://${host}` : `http://${host}`;

/**
 * Creates a URL based on the provided host and port.
 *
 * @param {string} host - The host name or IP address.
 * @param {number} port - The port number.
 * @returns {string} The constructed URL.
 */
export const createUrl = (host: string, port: number): string =>
  getUrl(`${host}:${port}`);
