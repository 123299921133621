import { styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import theme from './theme';

export const MyTableCell = styled(TableCell)(() => ({
  color: theme.palette.text.secondary,
  paddingLeft: '0px',
}));
export const MyTableRow = styled(TableRow)(() => ({
  '&:last-child th': {
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
}));
