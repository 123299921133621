import { Box, Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import NetworkHealth from './components/NetworkHealth';
import NodeGrid from './components/NodeGrid';
import theme from './components/shared/theme';
import Logo from './images/Redbelly_Brand_Logo.svg';
import {
  getNodesInfo,
  governorsListener,
  getNetworkSizeSafely,
  INodeNamePort,
  getNetworkConfigurationContractAddress,
} from './services/etherService';
import { convertBigNumberToInt } from './utils/helper';

function App() {
  const [networkConfigAddress, setNetworkConfigAddress] = useState('');
  const [networkSize, setNetworkSize] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [networkTolerance, setNetworkTolerance] = useState(0);
  const [nodesInfo, setNodesInfo] = useState<INodeNamePort[]>([]);
  const [nodeGovernors, setNodeGovernors] = useState<string[]>([]);
  const [activeNodes, setActiveNodes] = useState<number>(0);
  const [inconsistentBlockNumbers, setInconsistentBlockNumbers] =
    useState(false);

  useEffect(() => {
    (async () => {
      await getNetworkConfigurationContractAddress(setNetworkConfigAddress);
    })();
  }, []);

  useEffect(() => {
    if (networkConfigAddress) {
      (async () => {
        await governorsListener(networkConfigAddress, setNodeGovernors);
      })();
    }
  }, [networkConfigAddress]);

  useEffect(() => {
    (async () => {
      const nodesDetails = await getNodesInfo(networkConfigAddress);
      setNodesInfo(nodesDetails.nodes);
      setActiveNodes(nodesDetails.activeNodes);
      setInconsistentBlockNumbers(nodesDetails.inconsistentBlockNumbers);
    })();
  }, [networkConfigAddress, nodeGovernors]);

  useEffect(() => {
    (async () => {
      const size = await getNetworkSizeSafely(networkConfigAddress);
      const tolerance = Math.floor((parseFloat(size[0].toString()) - 1) / 3);
      setNetworkSize(convertBigNumberToInt(size[0]));
      setNetworkTolerance(tolerance);
    })();
  }, [networkConfigAddress, nodeGovernors]);

  useEffect(() => {
    if (networkSize && activeNodes) {
      setInitialLoading(false);
    }
  }, [networkSize, activeNodes]);

  return (
    <MyContainer>
      <Grid container rowSpacing={2} sx={{ width: '90%' }}>
        <Grid item xs={12}>
          <Grid container columnSpacing={{ xs: 0, sm: 3 }} alignItems="center">
            <Grid item>
              <img src={Logo} alt="RedbellyLogo" width="100%" />
            </Grid>
            <Grid item>
              <VerticalLine />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="h1"
                sx={{ color: theme.palette.primary.contrastText }}
              >
                Network Health
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <NetworkHealth
            loading={initialLoading}
            networkSize={networkSize}
            networkTolerance={networkTolerance}
            activeNodes={activeNodes}
            inconsistentBlockNumbers={inconsistentBlockNumbers}
          />
        </Grid>
        <Grid item xs={12}>
          {networkSize > 0 && activeNodes > 0 && (
            <NodeGrid
              networkConfigAddress={networkConfigAddress}
              nodesInfo={nodesInfo}
            />
          )}
        </Grid>
      </Grid>
    </MyContainer>
  );
}

export default App;
const VerticalLine = styled(Box)((props) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  height: '40px',
  [props.theme.breakpoints.only('xs')]: {
    display: 'none',
  },
}));
const MyContainer = styled(Box)((props) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  margin: '60px 0px 10px',
  [props.theme.breakpoints.only('xs')]: {
    margin: '30px 0px 5px',
  },
}));
