import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Readex Pro',
  fontSize: 14,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  htmlFontSize: 14,
  h1: {
    fontWeight: 700,
    lineHeight: '35px',
    fontSize: '28px',
  },
  h2: {
    fontWeight: 700,
    lineHeight: '31px',
    fontSize: '24px',
  },
  h3: {
    fontWeight: 400,
    lineHeight: '27px',
    fontSize: '20px',
  },
  h4: {
    fontWeight: 400,
    lineHeight: '22.5px',
    fontSize: '18px',
  },
  h5: {
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '16px',
  },
  h6: {
    fontWeight: 400,
    lineHeight: '17.5px',
    fontSize: '14px',
  },
  subtitle1: {
    fontWeight: 400,
    lineHeight: 1.75,
    fontSize: '14px',
  },
  subtitle2: {
    fontWeight: 400,
    lineHeight: 1.58,
    fontSize: '12px',
  },
  body1: {
    lineHeight: 1.5,
    fontWeight: 400,
    fontSize: '12px',
  },
  body2: {
    lineHeight: 1.45,
    fontWeight: 400,
    fontSize: '10px',
  },
  caption: {
    lineHeight: 1.66,
    fontWeight: 400,
    fontSize: '10px',
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '12px',
    letterSpacing: 1.1,
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 500,
    lineHeight: 1.77,
    fontSize: '16px',
    textTransform: 'capitalize',
  },
};

export default typography;
