/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, ethers } from 'ethers';

import {
  BootstrapContractsRegistry,
  BootstrapContractsRegistry__factory,
  NetworkConfigurationContract,
  NetworkConfigurationContract__factory,
} from '../typechain';
import { convertBigNumberToInt, createUrl, getUrl } from '../utils/helper';

export interface INodeNamePort {
  name: string;
  url: string;
  blockNumber: number;
}

/**
 * Loads and returns an instance of the NetworkConfigurationContract.
 *
 * This function creates and returns an instance of the NetworkConfigurationContract
 * by connecting it to the specified contract address and a JSON-RPC provider
 * using the URL from the environment configuration. Note that using a single URL
 * for the provider may be a point of contention.
 *
 * @returns {Contract} An instance of the NetworkConfigurationContract.
 */
const loadContract = (networkConfigAddress: string) => {
  const bootstrapNodeUrlsString = `${process.env.REACT_APP_BOOTSTRAP_NODE_URLS}`;
  const bootstrapNodeUrls: string[] = bootstrapNodeUrlsString.split(',');
  const configContractInstance = NetworkConfigurationContract__factory.connect(
    networkConfigAddress,
    // This can be a point of contention as everytime we're loading contract from one URL only.
    new ethers.providers.JsonRpcProvider(getUrl(bootstrapNodeUrls[0])),
  );
  return configContractInstance;
};

/**
 * Loads instances of the Bootstrap Contracts Registry Contract from multiple Red Belly bootstrap nodes.
 *
 * @returns {BootstrapContractsRegistryContract[]} An array of BootstrapContractsRegistry Contract instances.
 */
const loadBootstrapRegistryContracts = () => {
  const bootstrapNodeUrlsString = `${process.env.REACT_APP_BOOTSTRAP_NODE_URLS}`;
  const bootstrapNodeUrls: string[] = bootstrapNodeUrlsString.split(',');

  const contractInstances: BootstrapContractsRegistry[] = [];

  bootstrapNodeUrls.forEach((url) => {
    const provider = new ethers.providers.JsonRpcProvider(getUrl(url));
    
    // Create a contract instance
    const configContractInstance = BootstrapContractsRegistry__factory.connect(
      `${process.env.REACT_APP_BOOTSTRAP_REGISTRY_CONTRACT_ADDRESS}`,
      provider,
    );

    contractInstances.push(configContractInstance);
  });

  return contractInstances;
};

/**
 * Loads instances of the Network Configuration Contract from multiple Red Belly bootstrap nodes.
 *
 * @returns {NetworkConfigurationContract[]} An array of Network Configuration Contract instances.
 */
const loadNetworkConfigurationContracts = (networkConfigAddress: string) => {
  const bootstrapNodeUrlsString = `${process.env.REACT_APP_BOOTSTRAP_NODE_URLS}`;
  const bootstrapNodeUrls: string[] = bootstrapNodeUrlsString.split(',');

  const contractInstances: NetworkConfigurationContract[] = [];

  bootstrapNodeUrls.forEach((url) => {
    const provider = new ethers.providers.JsonRpcProvider(getUrl(url));

    // Create a contract instance
    const configContractInstance =
      NetworkConfigurationContract__factory.connect(
        networkConfigAddress,
        provider,
      );

    contractInstances.push(configContractInstance);
  });

  return contractInstances;
};

/**
 * Fetches the network size from multiple contract instances safely.
 *
 * This function loads contract instances from multiple URLs, sends requests to each
 * contract instance to retrieve network size, and returns the first valid response.
 * If none of the requests are successful, it throws an error.
 *
 * @returns {Promise<any>} A promise that resolves with the network size or rejects with an error.
 */
export const getNetworkSizeSafely = async (networkConfigAddress: string) => {
  const contractInstances = await loadNetworkConfigurationContracts(
    networkConfigAddress,
  );

  const promises = contractInstances.map(async (contractInstance) => {
    try {
      const size = await contractInstance.functions.getNetworkSize();
      return size;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error for contract at  `, error);
      return null;
    }
  });

  const resp = await getFirstValidResponse(promises);

  return resp;
};

/**
 * Fetches the network size from the NetworkConfigurationContract.
 *
 * @returns {Promise<BigNumber>} A promise that resolves with the network size or
 *   returns [BigNumber.from(0)] if an error occurs.
 */
export const getNetworkSize = async (networkConfigAddress: string) => {
  const result = loadContract(networkConfigAddress);
  try {
    const size = await result.functions.getNetworkSize();
    return size;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Cannot get size and tolerance: ', error);
  }
  return [BigNumber.from(0)];
};

/**
 * Fetches the list of governors from the NetworkConfigurationContract.
 *
 * @returns {Promise<string[]>} A promise that resolves with an array of governor addresses.
 */
export const getGovernors = async (networkConfigAddress: string) => {
  const contractInstance = loadContract(networkConfigAddress);
  const governors = await contractInstance.functions.getGovernors();
  return governors;
};

/**
 * Fetches governors from multiple contract instances safely.
 *
 * This function loads contract instances from multiple URLs, sends requests to each
 * contract instance to retrieve governors, and returns the first valid response.
 * If none of the requests are successful, it throws an error.
 *
 * @returns {Promise<any>} A promise that resolves with the governors or rejects with an error.
 */
export const getGovernorsSafely = async (networkConfigAddress: string) => {
  const contractInstances = await loadNetworkConfigurationContracts(
    networkConfigAddress,
  );

  const promises = contractInstances.map(async (contractInstance) => {
    try {
      const governors = await contractInstance.getGovernors();
      return governors;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error for contract at  `, error);
      return null;
    }
  });

  const resp = await getFirstValidResponse(promises);

  return resp;
};

/**
 * Retrieves node configurations for specified addresses from a contract instance.
 *
 * @param {string[]} addresses - An array of node addresses for which to retrieve configurations.
 * @returns {Promise<any>} A promise that resolves with the node configurations.
 */
export const getNodeConfig = async (
  networkConfigAddress: string,
  addresses: string[],
) => {
  const contractInstance = loadContract(networkConfigAddress);
  const nodeConfig =
    await contractInstance.functions.getNodeConfigurationsByAddresses(
      addresses,
    );
  return nodeConfig;
};

/**
 * Safely retrieves node configurations for specified addresses from multiple contract instances.
 *
 * @param {string} networkConfigAddress - Network configuration contract address.
 * @param {string[]} addresses - An array of node addresses for which to retrieve configurations.
 * @returns {Promise<any>} A promise that resolves with the node configurations or rejects with an error.
 */
export const getNodeConfigSafely = async (
  networkConfigAddress: string,
  addresses: string[],
) => {
  const contractInstances = await loadNetworkConfigurationContracts(
    networkConfigAddress,
  );

  const promises = contractInstances.map(async (contractInstance) => {
    try {
      const nodeConfig =
        await contractInstance.getNodeConfigurationsByAddresses(addresses);
      return nodeConfig;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error for contract at  `, error);
      return null;
    }
  });

  const resp = await getFirstValidResponse(promises);

  return resp;
};

/**
 * Retrieves the first valid response from an array of promises or throws an error if none are valid.
 *
 * @param {Promise<any[] | null>[]} promises - An array of promises to evaluate.
 * @returns {Promise<any>} The first valid response from the promises.
 * @throws {Error} If no valid responses are received.
 */
const getFirstValidResponse = async (
  promises: Promise<any[] | null>[],
): Promise<any> => {
  const result: any = await Promise.all(promises);

  if (result instanceof Error) {
    throw result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validResponses = result.filter((response: any) => response !== null);

  if (validResponses.length === 0) {
    throw new Error('No valid responses received.');
  }

  return validResponses[0];
};

/**
 * Fetches the latest block number from a JSON-RPC provider.
 *
 * This function creates a JSON-RPC provider using the provided URL and
 * fetches the latest block number from the Ethereum network.
 *
 * @param {string} url - The URL of the JSON-RPC provider.
 * @returns {Promise<number>} A promise that resolves with the latest block number
 *   or rejects with an error. If an error occurs, it returns -1.
 */
export const getBlockNumber = async (url: string) => {
  const provider = new ethers.providers.JsonRpcProvider({
    url,
  });

  try {
    const blockNumber = await provider.getBlockNumber();
    return blockNumber;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error while fetching block number: ', error);
  }
  return -1;
};

/**
 * Fetches information about network nodes and their block numbers.
 *
 * This function retrieves information about network nodes from governors, including
 * their hostnames and JSON-RPC ports. It then fetches the block numbers from these nodes
 * and determines the number of active nodes and whether block numbers are consistent.
 *
 * @returns {Promise<{ nodes: INodeNamePort[], activeNodes: number, inconsistentBlockNumbers: boolean }>}
 *   A promise that resolves with network nodes information or rejects with an error.
 */
export const getNodesInfo = async (networkConfigAddress: string) => {
  const nodes: INodeNamePort[] = [];
  const blockNumberPromises = [];
  let inconsistentBlockNumbers = false;
  let activeNodes = 0;
  let nodeConfig: any;
  try {
    const governors = await getGovernorsSafely(networkConfigAddress);

    nodeConfig = await getNodeConfigSafely(networkConfigAddress, governors);

    for (let index = 0; index < governors.length; index += 1) {
      const url = createUrl(
        nodeConfig[index].hostname,
        convertBigNumberToInt(nodeConfig[index].jsonRpcPort),
      );
      const blockNumberPromise = getBlockNumber(url);
      blockNumberPromises.push(blockNumberPromise);
    }
    const blockNumberPromisesResult = await Promise.all(blockNumberPromises);
    for (let index = 0; index < governors.length; index += 1) {
      if (blockNumberPromisesResult[index] >= 0) {
        activeNodes += 1;
      }
      if (
        index > 0 &&
        blockNumberPromisesResult[index] !==
          blockNumberPromisesResult[index - 1]
      ) {
        inconsistentBlockNumbers = true;
      }
      nodes.push({
        name: nodeConfig[index].hostname,
        url: createUrl(
          nodeConfig[index].hostname,
          convertBigNumberToInt(nodeConfig[index].jsonRpcPort),
        ),
        blockNumber: 0,
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Cannot get nodes info: ', error);
  }

  return { nodes, activeNodes, inconsistentBlockNumbers };
};

/**
 * Sets up a listener to track changes in governor addresses.
 *()
 * @param {React.Dispatch<React.SetStateAction<string[]>>} setNodeGovernors - A state update
 *   function for governor addresses in a React component.
 */
export const governorsListener = async (
  networkConfigAddress: string,
  setNodeGovernors: {
    (value: React.SetStateAction<string[]>): void;
    (arg0: string[]): void;
  },
) => {
  const contract = await loadContract(networkConfigAddress);
  contract.on('GovernorsUpdated', (addresses: string[]) => {
    setNodeGovernors(addresses);
  });
};

/**
 * Sets up a block listener to track Ethereum block updates.
 *
 * @param {React.Dispatch<React.SetStateAction<number>>} setBlocks - A state update function
 *   for the block number in a React component.
 * @throws {Error} Throws an error if the Redbelly URL is not provided in the environment.
 */
export const setBlockListener = (
  url: string,
  setBlocks: {
    (value: React.SetStateAction<number>): void;
    (arg0: number): void;
  },
) => {
  const provider = new ethers.providers.JsonRpcProvider(url);
  provider.on('block', (blockNumber) => {
    setBlocks(blockNumber);
  });
};

export async function getNetworkConfigurationContractAddress(setNetworkConfigAddress: {
  (value: React.SetStateAction<string>): void;
  (arg0: string): void;
}) {
  const contractInstances = await loadBootstrapRegistryContracts();

  const promises = contractInstances.map(async (contractInstance) => {
    try {
      const contractAddress = await contractInstance.functions.registry(
        'networkconfiguration',
      );
      return contractAddress;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error for contract at  `, error);
      return null;
    }
  });

  const [resp] = await getFirstValidResponse(promises);
  setNetworkConfigAddress(resp);
}
