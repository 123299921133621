import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { MyTableCell, MyTableRow } from './shared/StyledComponents';

import { INodeNamePort, setBlockListener } from '../services/etherService';

interface IFetchRowDetails {
  nodeInfo: INodeNamePort;
  networkConfigAddress: string;
}

function FetchNodeDetails({
  nodeInfo,
  networkConfigAddress,
}: IFetchRowDetails) {
  const [blocks, setBlocks] = useState<number>(0);
  useEffect(() => {
    if (networkConfigAddress) {
      (async () => {
        await setBlockListener(nodeInfo.url, setBlocks);
      })();
    }
  }, [networkConfigAddress, nodeInfo]);

  return (
    <MyTableRow key={nodeInfo.name}>
      <MyTableCell>
        <Typography variant="h4">{nodeInfo.name}</Typography>
      </MyTableCell>
      <MyTableCell sx={{ width: { xs: '30%', sm: '15%' } }}>
        <Typography variant="h4">{blocks >= 0 ? blocks : '-'}</Typography>
      </MyTableCell>
    </MyTableRow>
  );
}

export default FetchNodeDetails;
